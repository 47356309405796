body {
  background-color: #282c34;
  text-align: center;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1%;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  word-wrap: break-word;
}

h1 {
  color: whitesmoke;
}